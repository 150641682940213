<template>
  <div class="loading-wrapper">
    <span class="icon is-large">
      <spin-loader/>
      <span :class="['is-size-4', `has-text-${color}`]" style="padding-left: 20px;">Loading...</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'LoadingInline',
  props: {
    color: {
      type: String,
      default: 'accent'
    }
  }
}
</script>

<style>
.loading-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
</style>
